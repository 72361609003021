<template>
  <div class="cabinet__item-block">
    <button v-if="Object.keys(profile.form).length === 0" class="send primary" @click.prevent="openProfile">Заполнить</button>
    <div v-else>
      <button class="primary" @click.prevent="openProfile">Обновить данные</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import animation from '@/utils/animation';

export default {
  name: 'CabinetComponent',
  components: {},
  data: function () {
    return {
      data: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile',
      // accountSelected: 'getAccountSelected',
    }),
  },
  methods: {
    ...mapMutations({
      changeModals: 'changeModals',
    }),
    ...mapActions({
      // pullProfile: 'pullProfile',
    }),
    // getData() {
    // this.data = this.getAccounts[this.accountSelected];
    // },
    openProfile(e) {
      this.changeModals({ profile: { visible: true, data: {} } });
      animation.pressing(e);
    },
  },
  watch: {},
  created() {},
  mounted() {
    // this.pullProfile();
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped></style>
