<template>
  <div class="profile-form">
    <form class="form" @submit.prevent="submitForm">
      <div v-if="step === 0">
        <h4>Персональная информация</h4>
        <div class="form__unit_inline md_block">
          <div>
            <c-label hint-text="Ваше семейное положение" text="Семейное положение:" />
            <c-select
              v-model="profile.form.family"
              :options="familyStatusOptions"
              :required="true"
              title="Выберите ваше семейное положение"
            />
          </div>

          <div>
            <c-label hint-text="Укажите количество членов семьи, находящихся на иждивении" text="Количество иждивенцев:" />
            <input v-model="profile.form.dependents" autocomplete="off" required title="Количество иждивенцев" type="number" />
          </div>
        </div>

        <div class="form__unit_inline md_block">
          <div>
            <c-label hint-text="Ваш вид образования" text="Уровень образования:" />
            <c-select v-model="profile.form.education" :options="educationOptions" :required="true" title="Выберите ваш вид образования" />
          </div>

          <div>
            <c-label hint-text="Ваш вид профессиональной деятельности" text="Профессиональная деятельность:" />
            <c-select
              v-model="profile.form.activities"
              :options="activitiesOptions"
              :required="true"
              title="Ваш вид профессиональной деятельности"
            />
          </div>
        </div>

        <div class="form__unit_inline md_block">
          <div>
            <c-label hint-text="Ваш вид занятости" text="Вид занятости:" />
            <c-select v-model="profile.form.employment" :options="employmentOptions" :required="true" title="Выберите ваш вид занятости" />
          </div>

          <div>
            <c-label
              hint-text="Публичное должностное лицо (PEP) - это лицо, назначенное или избираемое, занимающее какую-либо должность в законодательном, исполнительном, административном или судебном органе государства, а также любое лицо, выполняющее какую-либо публичную функцию для государства"
              text="PEP:"
            />
            <c-select v-model="profile.form.pep" :options="pepOptions" :required="true" title="PEP" />
          </div>
        </div>

        <div class="form__unit_inline md_block">
          <div>
            <c-label hint-text="Напишите данные о вашем работодателе или сфере деятельности" text="Ваше описание занятости:" />
            <textarea v-model="profile.form.employer" title="Напишите данные о вашем работодателе"></textarea>
          </div>
        </div>
      </div>
      <div v-if="step === 1">
        <h4>Финансовая информация</h4>
        <div class="form__unit_inline md_block">
          <div>
            <c-label hint-text="Размер дохода полученного за последний год" text="Годовой доход:" />
            <c-select
              v-model="profile.form.income"
              :options="incomeOptions"
              :required="true"
              title="Размер дохода полученного за последний год"
            />
          </div>

          <div>
            <c-label hint-text="Основной источник дохода" text="Источник дохода:" />
            <c-select v-model="profile.form.source" :options="sourceOptions" :required="true" multiple title="Основной источник дохода" />
          </div>
        </div>

        <div class="form__unit_inline md_block">
          <div>
            <c-label
              hint-text="Оценочный чистый капитал, все собственные активы за вычетом недвижимости в которой вы проживаете"
              text="Чистый капитал:"
            />
            <c-select v-model="profile.form.worth" :options="worthOptions" :required="true" title="Оценочный чистый капитал" />
          </div>

          <div>
            <c-label hint-text="Укажиет ваш опыт работы на финансовых рынках" text="Опыт работы:" />
            <c-select
              v-model="profile.form.experience"
              :options="experienceOptions"
              :required="true"
              multiple
              title="Ваш опыт работы на финансовых рынках"
            />
          </div>
        </div>

        <div class="form__unit_inline md_block">
          <div>
            <c-label hint-text="Укажиет как часто вы совершаете операции или сделки с финансовыми инструментами" text="Периодичность:" />
            <c-select
              v-model="profile.form.frequency"
              :options="frequencyOptions"
              :required="true"
              multiple
              title="Укажиет как часто вы совершаете операции или сделки с финансовыми инструментами"
            />
          </div>

          <div>
            <c-label hint-text="Выберите какими услугами вы ранее пользовались" text="Опыт получения услуг:" />
            <c-select
              v-model="profile.form.services"
              :options="servicesOptions"
              :required="true"
              multiple
              title="Выберите какими услугами вы ранее пользовались"
            />
          </div>
        </div>

        <div class="form__unit_inline md_block">
          <div>
            <c-label hint-text="Укажите процент инвестиционного капитала, относительно всех ваших активов" text="Доля инвес. капитала:" />
            <c-select
              v-model="profile.form.capital_ratio"
              :options="ratioOptions"
              :required="true"
              title="Укажите процент инвестиционного капитала, относительно всех ваших активов"
            />
          </div>

          <div>
            <c-label hint-text="Какой период инвестирования вы для себя рассматриваете" text="Срок инвестирования:" />
            <c-select
              v-model="profile.form.period"
              :options="periodOptions"
              :required="true"
              title="Какой период инвестирования вы для себя рассматриваете"
            />
          </div>
        </div>

        <div class="form__unit_inline md_block">
          <div>
            <c-label hint-text="Какая из инвестиционных стратегий вам ближе и вы планируете ее использовать" text="Цель инвестиций:" />
            <c-select
              v-model="profile.form.purpose"
              :options="purposeOptions"
              :required="true"
              title="Какая из инвестиционных стратегий вам ближе и вы планируете ее использовать"
            />
          </div>
        </div>
      </div>

      <div class="form__footer">
        <button :disabled="step === 3" class="primary center" type="submit" @click="animateButton">
          {{ waitingCode ? 'Новый код?' : 'Отправить' }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
// import CCheck from '@/components/UI/ComponentCustomCheck.vue';
import CSelect from '@/components/UI/ComponentSelect.vue';
// import utils from '@/utils';
import animation from '@/utils/animation';
import CLabel from '@/components/UI/ComponentLabel.vue';

const focus = {
  mounted: (el) => el.focus(),
};

export default {
  name: 'ProfileComponent',
  emits: ['close', 'refuse', 'confirm'],
  components: { CSelect, CLabel },
  directives: {
    focus,
  },
  data() {
    return {
      icons,
      familyStatusOptions: [
        { text: 'Не в браке', value: 'single' },
        { text: 'Состою в браке', value: 'married' },
        { text: 'В разводе', value: 'divorced' },
      ],
      educationOptions: [
        { text: 'Высшее образование в области экономики и финансов', value: 'economic_high' },
        { text: 'Высшее образование не в области экономики и финансов', value: 'non_economic_high' },
        { text: 'Иное образование', value: 'other' },
      ],
      activitiesOptions: [
        { text: 'Аналитик финансовых рынков', value: 'analyst' },
        { text: 'Инвестиционный/Финансовый консультант', value: 'consultant' },
        { text: 'Брокер/Дилер финансовых инструментов', value: 'broker_dealer' },
        { text: 'Управляющий инвестиционным портфелем', value: 'portfolio_manager' },
        { text: 'Нет профессионального финансового опыта', value: 'non_financial' },
      ],
      employmentOptions: [
        { text: 'Наемный работник', value: 'employed' },
        { text: 'Владелец бизнеса', value: 'owner' },
        { text: 'Студент', value: 'student' },
        { text: 'Пенсионер', value: 'retired' },
        { text: 'Самозанятый', value: 'self_employed' },
        { text: 'Иное', value: 'other' },
      ],
      pepOptions: [
        { text: 'Нет, не являюсь PEP', value: 'not_pep' },
        { text: 'Да, являюсь PEP', value: 'pep' },
      ],
      incomeOptions: [
        { text: '0$ - 10 000$', value: '10000' },
        { text: '10 000$ - 50 000$', value: '50000' },
        { text: '50 000$ - 100 000$', value: '100000' },
        { text: '100 000$ - 500 000$', value: '500000' },
        { text: '500 000$ - 1 000 000$', value: '1000000' },
        { text: '>1 000 000$', value: '1000001' },
      ],
      sourceOptions: [
        { text: 'Дивиденды/Проценты', value: 'fixed' },
        { text: 'Доход от аренды', value: 'rental' },
        { text: 'Доход от бизнеса', value: 'business' },
        { text: 'Наследство', value: 'inheritance' },
        { text: 'Пенсия', value: 'pension' },
        { text: 'Другой доход', value: 'other' },
      ],
      worthOptions: [
        { text: '0$ - 10 000$', value: '10000' },
        { text: '10 000$ - 50 000$', value: '50000' },
        { text: '50 000$ - 100 000$', value: '100000' },
        { text: '100 000$ - 500 000$', value: '500000' },
        { text: '500 000$ - 1 000 000$', value: '1000000' },
        { text: '>1 000 000$', value: '1000001' },
      ],
      experienceOptions: [
        { text: 'Денежный рынок (Депозиты, облигации и др.)', value: 'fixed' },
        { text: 'Акции и биржевые фонды', value: 'stock' },
        { text: 'Фьючерсы', value: 'futures' },
        { text: 'Опционы', value: 'options' },
        { text: 'Форекс', value: 'forex' },
      ],
      frequencyOptions: [
        { text: 'Никогда', value: 'never' },
        { text: 'Ежегодно', value: 'yearly' },
        { text: 'Ежемесячно', value: 'monthly' },
        { text: 'Еженедельно', value: 'weekly' },
        { text: 'Ежедневно', value: 'dayly' },
      ],
      servicesOptions: [
        { text: 'Брокерские услуги', value: 'brokerage' },
        { text: 'Управления активами', value: 'assets_management' },
        { text: 'Финансового консультанта', value: 'advisor' },
        { text: 'Другие финансовые услуги', value: 'other' },
      ],
      ratioOptions: [
        { text: '0% - 25%', value: '25' },
        { text: '25% - 50%', value: '50' },
        { text: '50% - 75%', value: '75' },
        { text: '75% - 100%', value: '100' },
      ],
      periodOptions: [
        { text: 'до 1 года', value: '1' },
        { text: 'от 1 года до 3 лет', value: '3' },
        { text: 'от 3 лет до 10 лет', value: '10' },
        { text: 'более 10 лет', value: '11' },
      ],
      purposeOptions: [
        { text: 'Сохранение капитала', value: 'preservation' },
        { text: 'Получение дохода', value: 'income' },
        { text: 'Хеджирование', value: 'hedge' },
        { text: 'Рост капитала', value: 'growth' },
        { text: 'Доход от торговли', value: 'trading' },
        { text: 'Спекуляции', value: 'speculation' },
      ],
      code: null,
      // expIndefinite: false,
      // emailOptional: false,

      waitingCode: false,
      attempts: 0,
      step: 0,
    };
  },
  props: {
    visible: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile',
      // accounts: 'getAccounts',
    }),
  },
  methods: {
    ...mapMutations({
      changeNotifications: 'changeNotifications',
      changeModals: 'changeModals',
    }),
    ...mapActions({
      // pullAccounts: 'pullAccounts',
      // updateAccountSelected: 'updateAccountSelected',
      sendEnrichment: 'sendEnrichment',
      // deleteDocuments: 'deleteDocuments',
      createInstruction: 'createInstruction',
      signingInstruction: 'signingInstruction',
    }),
    // validateInputFIO(target) {
    //   target.value = target.value
    //     .replace(/[^A-Za-zА-Яа-я ]/g, '')
    //     .split(' ')
    //     .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    //     .join(' ');
    // },
    // validateInputLatinNumeric(target) {
    //   target.value = target.value.replace(/[^A-Za-z\d]/g, '').toUpperCase();
    // },
    lock() {
      // return this.personalData.options.status === 'opening';
      return false;
    },
    close() {
      this.$emit('close');
    },
    refuse() {
      this.$emit('refuse');
    },
    animateButton(e) {
      animation.pressing(e);
    },
    async submitForm(e) {
      console.log(e);

      if (this.step === 0) {
        //   const { fio, sex, birthdate, birthplace, email } = this.personalData.form;
        //   this.sendEnrichment({ form: { fio, sex, birthdate, birthplace, email } });
      } else if (this.step === 1) {
        //   const { citizenship, identityType, identity, issued, issuedDate, expDate } = this.personalData.form;
        //   const files = this.personalData.files.filter((each) => each.documentType === 'clients_identification' && each.status === 'local');
        //   this.sendEnrichment({ form: { citizenship, identityType, identity, issued, issuedDate, expDate, files } });
        // } else if (this.step === 2) {
        //   const { taxCountry, usTaxResident, postalcode, address, addressDate } = this.personalData.form;
        //   const files = this.personalData.files.filter((each) => each.documentType === 'clients_address' && each.status === 'local');
        //   this.sendEnrichment({ form: { taxCountry, usTaxResident, postalcode, address, addressDate, files } });
        // } else if (this.step === 3) {
        //   const submit = e.target.querySelector('button[type="submit"]');
        //   submit.disabled = true;
        //   const response = await this.createInstruction({
        //     form: this.personalData.form,
        //     options: { forced: this.waitingCode },
        //   });
        //   let text = '';
        //   let wait = 60000;
        //   if (response.status === 'sent') {
        //     text += 'Код отправлен на номер ' + response.data.chat + ', в ' + utils.getLocaleDateTimeString(response.data.sent);
        //     if (response.data.sent < new Date().valueOf() - 60 * 1000) {
        //       wait = 5000;
        //       text += '<br />Он еще актуален';
        //     }
        //     this.changeNotifications({ text, style: 'green', type: 'success', duration: 10 });
        //     this.waitingCode = true;
        //     setTimeout(() => (submit.disabled = false), wait);
        //     return void console.log('Ожидание ввода кода');
        //   }
      }
      if (this.step < 3) this.step++;
    },
    // async checkCode(target) {
    //   if (this.attempts > 10) {
    //     this.form.code = '';
    //     target.disabled = true;
    //     const text = 'Вы вводите много неверных кодов!!!<br />Начните заново!!!';
    //     return void this.changeNotifications({ text, style: 'red', type: 'danger', duration: 600 });
    //   }
    //   target.value = target.value.replace(/\D+/g, '');
    //   if (target.value.length === 6) {
    //     const submit = target.closest('form').querySelector('button[type="submit"]');
    //     submit.disabled = true;
    //     await this.processingCode(this.personalData.form);
    //     submit.disabled = false;
    //   }
    // },
    // async processingCode(form) {
    //   const response = await this.signingInstruction({ form, options: { code: this.code } });
    //   let text = '';
    //   if (response.error) {
    //     this.attempts++;
    //     if (response.status === 'wrong') text += 'Код не совпадает, попробуйте еще раз';
    //     else if (response.status === 'outdated') text += 'Код устарел, вам был выслан новый код<br />Введите новый код';
    //     else text += 'Неизвестная ошибка!!!';
    //     this.code = '';
    //     return void this.changeNotifications({ text, style: 'red', type: 'danger', duration: 10 });
    //   }
    //   if (response.status === 'signed') {
    //     this.changeNotifications({
    //       text: 'Вы подали поручение на заключение договора.<br />Ожидайте обработки вашего поручения',
    //       style: 'green',
    //       type: 'success',
    //       duration: 10,
    //     });
    //     await this.pullAccounts();
    //     this.updateAccountSelected(Object.keys(this.accounts)[0]);
    //     this.pullPersonalData();
    //     return void console.log('Принято');
    //   }
    //   throw new Error('processingCode unexpected error!!!');
    // },
    // async removeFile(file) {
    //   if (file.status === 'local') {
    //     const index = this.personalData.files.findIndex((each) => each.url === file.url);
    //     this.personalData.files.splice(index, 1);
    //   } else {
    //     await this.deleteDocuments({ revision: file.url });
    //     this.pullPersonalData();
    //   }
    // },
  },
  watch: {
    // personalData: {
    //   handler(newState) {
    //     if (newState.options.status === 'opening') this.close();
    //   },
    //   deep: true,
    // },
  },
  created() {},
  mounted() {
    // this.step = 1;
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.profile-form {
  max-width: 600px;
  @include breakpoint-down(md) {
    max-width: 100%;
    width: 100%;
  }
}
</style>
