<template>
  <div class="authentication">
    <div class="authentication-form">
      <div class="wrapper-inner">
        <div class="logo">
          <img :src="img.blueLogo" alt="" />
        </div>
        <form class="form" @submit.prevent="processingPhone">
          <div v-if="!waitingCode" class="form__unit">
            <div>
              <c-label hint-text="Укажите актуальный номер телефона" text="Телефон" />
              <vue-tel-input v-model="form.phone" v-focus v-bind="telInputData" @validate="validatePhone" />
            </div>
          </div>

          <div v-if="waitingCode" :class="error ? 'danger' : ''" class="form__unit">
            <div class="title">{{ error ? 'Неверный код' : null }}</div>
            <input v-model="code" v-focus placeholder="Введите код" type="text" @input="checkCode($event.target)" />
          </div>

          <div v-if="!waitingCode" class="info">
            <div class="item">
              <div>
                <input required type="checkbox" />
              </div>
              <div>
                <a href="https://nc.ptfin.kz/index.php/s/92wR54F4dgiRQE2" target="_blank">
                  я ознакомлен и даю Cогласие на обработку песональных данных
                </a>
                <a href="https://nc.ptfin.ru/index.php/s/J2tm5skA5HdHJN2" target="_blank"> Cоглашение об электронном документообороте </a>
              </div>
            </div>
          </div>
          <div class="form__footer">
            <button class="primary center" type="submit">{{ waitingCode ? 'Новый код?' : 'Получить код' }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { img } from '@/assets/svg-img';
import utils from '@/utils';
import CLabel from '@/components/UI/ComponentLabel.vue';

const focus = {
  mounted: (el) => el.focus(),
};

export default {
  name: 'registration-page',
  components: { CLabel },
  data() {
    return {
      img,
      error: false,
      waitingCode: false,
      form: {
        id: null,
        phone: null,
      },
      isPhoneValid: true,
      code: null,
      attempts: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
    telInputData() {
      return {
        disabledFetchingCountry: false,
        enabledFlags: true,
        disabledFormatting: false,
        dropdownOptions: {
          showDialCodeInSelection: true,
          showDialCodeInList: true,
          showFlags: true,
          showSearchBox: true,
        },
        inputOptions: {
          maxlength: 16,
          placeholder: 'Введите номер телефона',
          showDialCode: true,
        },
        mode: 'international',
        preferredCountries: [
          'KZ',
          'RU',
          'KG',
          'UZ',
          // , 'AZ', 'AM'
        ],
        validCharactersOnly: true,
      };
    },
  },
  methods: {
    ...mapMutations({
      changeNotifications: 'changeNotifications',
    }),
    ...mapActions({
      resetState: 'resetState',
      sendPhone: 'sendPhone',
    }),
    validatePhone(e) {
      this.isPhoneValid = e.valid;
    },
    async processingPhone(e) {
      e.preventDefault();
      if (!this.isPhoneValid) {
        return void this.changeNotifications({
          text: 'Введен некорректный номер',
          style: 'red',
          type: 'danger',
          duration: 60,
        });
      }

      e.target.disabled = true;
      let wait = 60000;
      const response = await this.sendPhone({ form: this.form, options: { forced: this.waitingCode, code: null } });
      const contact = response.data.contact;
      let text = '';
      if (response.error) {
        if (response.status === 'duplication') text += 'Ваше контактый номер имеется у нескольких клиентов.<br />';
        text += 'Обратитесь к менеджеру по номеру телефона +7 707 350 7550';
        return void this.changeNotifications({ text, style: 'red', type: 'danger', duration: 60 });
      }

      if (response.status === 'authorized') {
        if (contact.terminal_users.length > 0) {
          text += 'Вы уже зарегистрированы!';
          this.changeNotifications({ text, style: 'green', type: 'success', duration: 5 });
          return void this.$router.push({ name: 'login' });
        }
        return void this.$router.push({ name: 'password' });
      }
      if (response.status === 'sent') {
        text += 'Код отправлен на номер ' + response.data.chat + ', в ' + utils.getLocaleDateTimeString(response.data.sent);
        if (response.data.sent < new Date().valueOf() - 60 * 1000) {
          wait = 1000;
          text += '<br />Он еще актуален';
        }
        this.changeNotifications({ text, style: 'red', type: 'success', duration: 10 });
        this.waitingCode = true;
        setTimeout(() => (e.target.disabled = false), wait);
        return void console.log('Ожидание ввода кода');
      }
      throw new Error('processingPhone unexpected error!!!');
    },
    async checkCode(target) {
      if (this.attempts > 10) {
        this.code = '';
        target.disabled = true;
        const text = 'Вы вводите много неверных кодов!!!<br />Начните заново!!!';
        return void this.changeNotifications({ text, style: 'red', type: 'danger', duration: 600 });
      }
      target.value = target.value.replace(/\D+/g, '');
      if (target.value.length === 6) {
        this.processingCode(this.form);
      }
    },
    async processingCode(form) {
      const response = await this.sendPhone({ form, options: { code: this.code } });
      let text = '';
      if (response.error) {
        this.attempts++;
        if (response.status === 'wrong') text += 'Код не совпадает, попробуйте еще раз';
        else if (response.status === 'outdated') text += 'Код устарел, вам был выслан новый код<br />Введите новый код';
        else text += 'Неизвестная ошибка!!!';
        this.code = '';
        return void this.changeNotifications({ text, style: 'red', type: 'danger', duration: 10 });
      }
      if (response.status === 'authorized') return void this.$router.push({ name: 'password' });
      throw new Error('processingCode unexpected error!!!');
    },
  },
  watch: {},
  created() {
    this.resetState();
  },
  directives: {
    focus,
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.info {
  .item {
    display: flex;
    align-items: flex-start;

    div:first-child {
      margin-right: 10px;
    }

    a {
      color: var(--pale-violet);
      margin-bottom: 5px;
      display: block;
      font-size: 12px;
      line-height: 14px;
      text-decoration: none;

      &:last-child {
        margin: 0;
      }
    }
  }
}
</style>
