<template>
  <div class="cabinet-component cabinet">
    <tabs>
      <!-- АККАУНТ -->
      <tab id="account-data" name="Аккаунт">
        <div class="cabinet__unit">
          <div class="cabinet__unit-title">Данные аккаунта</div>
          <account-data />
        </div>
        <div class="cabinet__unit">
          <div class="cabinet__unit-title">Профиль</div>
          <profile-data />
        </div>
        <div class="cabinet__unit">
          <div class="cabinet__unit-title">Документы</div>
          <documents />
        </div>
      </tab>
      <!-- РЕКВИЗИТЫ -->
      <tab id="requisites" name="Реквизиты">
        <div class="cabinet__unit">
          <div class="cabinet__unit-title">Пополнение счета</div>
          <bank-requisites />
        </div>
      </tab>
      <!-- НАСТРОЙКИ -->
      <tab id="settings" name="Настройки">
        <div class="cabinet__unit">
          <div class="cabinet__unit-title">Телеграм</div>
          <telegram />
        </div>
        <div class="cabinet__unit">
          <div class="cabinet__unit-title">Ключи</div>
          <key />
        </div>
        <div class="cabinet__unit">
          <div class="cabinet__unit-title">Смена пароля</div>
          <change-pass />
        </div>
        <div class="cabinet__unit">
          <terminal-data-setting />
        </div>
      </tab>
    </tabs>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import AccountData from '@/components/cabinet/AccountData.vue';
import ProfileData from '@/components/cabinet/ProfileData.vue';
import Documents from '@/components/cabinet/Documents.vue';
import Key from '@/components/cabinet/Key.vue';
import Telegram from '@/components/cabinet/Telegram.vue';
import TerminalDataSetting from '@/components/TerminalDataSetting.vue';
import ChangePass from '@/components/cabinet/ChangePass.vue';
import BankRequisites from '@/components/cabinet/BankRequisites.vue';
import { Tabs, Tab } from 'vue3-tabs-component';

export default {
  name: 'CabinetComponent',
  components: {
    TerminalDataSetting,
    AccountData,
    ProfileData,
    Documents,
    Key,
    Telegram,
    ChangePass,
    BankRequisites,
    Tabs,
    Tab,
  },
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({}),
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss">
@import '@/style/cabinet';
@import '@/style/form';
</style>
