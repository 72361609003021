<template>
  <div class="main-page">
    <div class="container">
      <div class="new-wrap">
        <div class="main-left">
          <div class="chart-wrap">
            <div class="widget-bg">
              <div class="selected">{{ selectedSymbol }}</div>
              <chart-component />
            </div>
          </div>

          <div :style="{ transition: 'height ' + delay + 'ms' }" class="portfolio-wrap">
            <div class="widget-bg">
              <div class="tabs-header-line">
                <div class="table-setting">
                  <div v-if="selectedTab === 'Portfolio'">
                    <!--                    portfoio setting-->
                  </div>
                  <div v-if="selectedTab === 'Orders'" style="min-width: 120px">
                    <!-- <component-select :options="days" default-option="за 1 день" /> -->
                  </div>
                  <div v-if="selectedTab === 'Money'">
                    <!--                    money setting-->
                  </div>

                  <div
                    :title="tableHeight ? 'свернуть панель' : 'развенруть панель'"
                    class="change-height-icon"
                    @click="collapse('.main-left')"
                  >
                    <img :src="tableHeight ? icons.collapse : icons.height" alt="" />
                  </div>

                  <div
                    :title="fullScreenTable ? 'стандартный размер' : 'на весь экран'"
                    class="change-height-icon"
                    @click="fullHeight('.main-left')"
                  >
                    <img :src="fullScreenTable ? icons.defaultHeight : icons.fullHeight" alt="" />
                  </div>
                </div>
              </div>
              <div class="index-tabs">
                <tabs :options="{ useUrlFragment: false }" @changed="tabChanged">
                  <tab name="Portfolio">
                    <portfolio-table-component />
                  </tab>
                  <tab name="Orders">
                    <order-table-component />
                  </tab>
                  <!--                  <tab name="Money">-->
                  <!--                  </tab>-->
                </tabs>
              </div>
              <!-- //todo исправить html -->
              <!-- <div @click="updateWS" style="position: absolute; right: 38px; top: -7px; font-size: 30px; cursor: pointer">&#x21BB;</div> -->
            </div>
          </div>
        </div>
        <div class="main-right">
          <div class="watch-list-wrap">
            <div class="widget-bg">
              <watchlist-component class="watchlist" />
            </div>
          </div>
          <div class="widget-bg">
            <!--            <mass-order-component />-->
            <order-form-component />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
import WatchlistComponent from '@/components/WatchlistComponent';
import OrderFormComponent from '@/components/OrderFormComponent';
// import MassOrderComponent from '@/components/MassOrderForm.vue';
import PortfolioTableComponent from '@/components/PortfolioTableComponent';
import ChartComponent from '@/components/ChartComponent';
import { Tabs, Tab } from '../../node_modules/vue3-tabs-component';
import OrderTableComponent from '@/components/OrderTableComponent';
// import ComponentSelect from '@/components/UI/ComponentSelect.vue';

export default {
  name: 'IndexView',
  components: {
    // ComponentSelect,
    ChartComponent,
    WatchlistComponent,
    OrderFormComponent,
    // MassOrderComponent,
    PortfolioTableComponent,
    Tabs,
    Tab,
    OrderTableComponent,
  },
  data() {
    return {
      icons,
      fullScreenTable: false,
      tableHeight: true,
      days: [
        { value: '1', text: 'за 1 день' },
        { value: '3', text: 'за 3 дня' },
        { value: '7', text: 'за 7 дней' },
        { value: '15', text: 'за 15 дней' },
        { value: '30', text: 'за 30 дей' },
        { value: 'all', text: 'all' },
      ],
      selectedTab: '',
      orderFormKey: new Date().getTime(),
      windowWidth: window.innerWidth,
      delay: 200,
    };
  },
  computed: {
    ...mapGetters({
      selectedSymbol: 'getSelectedSymbol',
      isMobile: 'isMobile',
    }),
  },
  methods: {
    ...mapMutations({
      toggleChartHeight: 'toggleChartHeight',
      changeSymbols: 'changeSymbols',
    }),
    ...mapActions({
      getApiPrices: 'getApiPrices',
      updateSelectedSymbol: 'updateSelectedSymbol',
    }),
    getEeternalParams() {
      const urlParams = new URLSearchParams(window.location.search);
      const symbol = urlParams.get('symbol');
      const source = urlParams.get('source');
      const asset_category = urlParams.get('type');

      if (symbol && source && asset_category) {
        this.changeSymbols({
          [symbol]: { symbol, source, asset_category },
        });
        this.updateSelectedSymbol(symbol);
        this.clearURL();
      }
    },
    clearURL() {
      const currentUrl = window.location.href;
      const newUrl = currentUrl.split('?')[0];
      window.history.replaceState(null, '', newUrl);
    },
    tabChanged(e) {
      this.selectedTab = e.tab.name;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    confirmReload(event) {
      const confirmationMessage = undefined;
      event.returnValue = confirmationMessage;
      return confirmationMessage;
    },
    resizeHeightChart() {
      setTimeout(() => {
        this.toggleChartHeight();
      }, this.delay);
    },
    fullHeight(item) {
      let block = document.querySelector(item);
      block.classList.toggle('full-height');
      this.fullScreenTable = !this.fullScreenTable;
      this.tableHeight = true;
      this.resizeHeightChart();
    },
    async collapse(item) {
      let block = document.querySelector(item);
      block.classList.toggle('collapse');
      block.classList.remove('full-height');
      this.fullScreenTable = false;
      (this.tableHeight = !this.tableHeight), this.resizeHeightChart();
    },
  },
  watch: {
    windowWidth(newWidth) {
      // todo
      if (newWidth <= 800) this.$router.push({ name: 'portfolio' });
    },
  },
  created() {
    if (this.isMobile) this.$router.push({ name: 'portfolio' });
  },
  mounted() {
    this.getEeternalParams();
    window.addEventListener('resize', this.handleResize);
    window.addEventListener('beforeunload', this.confirmReload);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('beforeunload', this.confirmReload);
  },
};
</script>

<style lang="scss" scoped>
.main-page {
  .change-height-icon {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
    cursor: pointer;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  //.arrow-collapse {
  //  margin-left: 10px;
  //  width: 20px;
  //  height: 20px;
  //  display: flex;
  //  align-items: center;
  //  justify-content: center;
  //  z-index: 9999;
  //  cursor: pointer;
  //  transform: rotateX(180deg);
  //  transform-origin: center;
  //  transition: all 0.2s ease-in-out;
  //
  //  img {
  //    width: 100%;
  //  }
  //}

  .selected {
    font-size: 14px;
    font-weight: 600;
    padding-left: 10px;
    height: 24px;
    display: flex;
    align-items: center;
  }

  .new-wrap {
    display: flex;
  }

  .main-right {
    flex: 1;
    flex-shrink: 0;
    max-width: 360px;
    width: 100%;
    margin-left: 1px;
    overflow: hidden;
    padding: 2px;
    height: calc(100vh - var(--setting-line-height));
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .main-left {
    width: calc(100% - 360px);
    flex: 1;
    height: calc(100vh - var(--setting-line-height));

    &.collapse {
      .portfolio-wrap {
        height: 30px;
      }

      .chart-wrap {
        height: calc(100% - 30px);
      }

      .arrow-collapse {
        transform: rotateX(0);
      }
    }

    &.full-height {
      .portfolio-wrap {
        height: 100%;
      }

      .chart-wrap {
        height: 2px;
      }
    }
  }

  .portfolio-wrap {
    height: 50%;
    position: relative;
    overflow: hidden;
    z-index: 9999;

    .widget-bg {
      height: 100%;
    }
  }

  .chart-wrap {
    height: 50%;
    transition: height 0.2s ease-in-out;

    .widget-bg {
      height: 100%;
    }
  }

  .watch-list-wrap {
    height: 100%;
    overflow: hidden;

    .widget-bg {
      height: 100%;
    }

    .watchlist {
      height: calc(100% - 25px);
    }
  }
}

.table-setting {
  display: flex;
  //min-width: 30%;
}

.tabs-header-line {
  display: flex;
  //align-items: center;
  position: absolute;
  top: 5px;
  right: 5px;
}
</style>
