<template>
  <div id="chart-type-k-line" style="height: calc(100% - 24px)" />
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
// eslint-disable-next-line no-unused-vars
import { init, dispose, registerLocale } from 'klinecharts';
import { setting } from './Chart/default';
import { registerOrderIndicator } from './Chart/registerOrderIndicator';

export default {
  name: 'ChartComponent',
  data: function () {
    return {
      data: {},
      chart: null,
      historyReceived: false,
      panelId: null,
      localTimeframe: null,
      filteredItems: null,
      localmainIndicators: new Set(),
    };
  },
  computed: {
    ...mapGetters({
      selectedSymbol: 'getSelectedSymbol',
      symbols: 'getSymbols',
      timeFrameSelected: 'getTimeFrameSelected',
      chartType: 'getChartType',
      chartMainIndicators: 'getChartMainIndicators',
      chartSubIndicator: 'getChartSubIndicator',
      isMobile: 'isMobile',
      chartHeight: 'getChartHeight',
      orders: 'getOrders',
      order: 'getOrder',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      pullChartData: 'pullChartData',
    }),
    getActiveOrder() {
      return this.orders.filter(
        (item) => ['approval', 'pending', 'accepted'].includes(item.status) && item.instrument.symbol === this.selectedSymbol,
      );
    },
    async requestChart({ symbol, timeframe, id = 0 }) {
      if (!symbol || !timeframe || id > 10) return;
      this.data = {};
      if (this.symbols[symbol] === undefined) {
        setTimeout(() => {
          this.requestChart({ symbol, timeframe, id: id++ });
        }, 500);
        return null;
      }
      this.historyReceived = false;
      if (!this.isMobile) this.chart.clearData();
      if (!this.isMobile) this.chart.adjustPaneViewport(true, true, true, true, true);
      this.data = (
        await this.pullChartData({
          instrument: {
            symbol: this.symbols[symbol].symbol,
            source: this.symbols[symbol].source,
            asset_category: this.symbols[symbol].asset_category,
          },
          period: parseInt(timeframe),
          limit: 1500,
        })
      )[symbol];

      if (this.data && this.data.chart && this.data.chart.full) {
        this.data.chart.full.push(this.data.chart.last);
        this.historyReceived = true;
        this.chart.applyNewData(this.data.chart.full);
        this.drawIndicators();
      }
    },
    addActiveClass(e) {
      let all = document.querySelectorAll('.buttons-wrap button');
      all.forEach(function (o) {
        o.className = '';
      });
      e.target.className = e.target.className === 'active' ? '' : 'active';
    },
    drawIndicators() {
      if (this.data.symbol === this.selectedSymbol) {
        this.localmainIndicators.clear();
        this.chart.removeIndicator('candle_pane');
        for (const each of this.chartMainIndicators) this.localmainIndicators.add(each.name);
        for (const activeOrder of this.getActiveOrder()) {
          if (activeOrder.status === 'approval') activeOrder.transaction_id = activeOrder.id;
          this.localmainIndicators.add(registerOrderIndicator(activeOrder, this.chart));
        }
        for (const each of this.localmainIndicators) {
          this.chart.createIndicator(each, true, { id: 'candle_pane' });
        }
      }
    },
    handleOrientationChange() {
      setTimeout(() => {
        this.chart.resize();
      }, 100);
    },
  },
  watch: {
    chartHeight() {
      this.chart.resize();
    },
    chartType(newState) {
      this.chart.setStyles({
        candle: { type: newState },
      });
    },
    chartMainIndicators: {
      handler() {
        this.drawIndicators();
      },
      deep: true,
    },
    chartSubIndicator(newState) {
      this.panelId = this.chart?.createIndicator(newState, false, { id: this.panelId });
    },
    selectedSymbol() {
      this.requestChart({ symbol: this.selectedSymbol, timeframe: this.localTimeframe });
    },
    timeFrameSelected(newState) {
      this.localTimeframe = newState;
      this.requestChart({ symbol: this.selectedSymbol, timeframe: this.localTimeframe });
    },
    orders: {
      handler() {
        this.drawIndicators();
      },
      deep: true,
    },
  },
  created() {
    if (this.isMobile) this.requestChart({ symbol: this.selectedSymbol, timeframe: this.timeFrameSelected });
  },
  mounted() {
    const type = this.isMobile ? 'mobile' : 'desktop';
    registerLocale('ru-RU', {
      time: 'Время: ',
      open: 'Открытие: ',
      high: 'Максимум: ',
      low: 'Минимум: ',
      close: 'Закрытие: ',
      volume: 'Объем: ',
      change: 'Изменение: ',
    });
    window.addEventListener('orientationchange', this.handleOrientationChange);
    this.chart = init('chart-type-k-line', setting(type));
    window.api.marketData.on('chart_update', (data) => {
      if (this.historyReceived && data.symbol === this.selectedSymbol) {
        this.chart.updateData(data.chart.last);
      }
    });
  },
  unmounted() {
    dispose('chart-type-k-line');
    window.removeEventListener('orientationchange', this.handleOrientationChange);
  },
};
</script>

<style lang="scss"></style>
