<template>
  <div class="cabinet__description">
    Telegram bot очень удобен, позволяет быть на связи даже при ограниченном интергете и хранит историю всех отправленных приказов
  </div>
  <button v-if="!telegramId" class="primary" @click.prevent="invite">Пригласить</button>
  <div v-if="telegramId">{{ telegramId }}</div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import animation from '@/utils/animation';

export default {
  name: 'TelegramComponent',
  components: {},
  data: function () {
    return {
      telegramId: null,
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      inviteTelegram: 'inviteTelegram',
    }),
    invite(e) {
      animation.pressing(e);
      this.inviteTelegram();
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped></style>
