<template>
  <form class="form">
    <div class="form__unit">
      <c-label text="Key first" />
      <div>
        <input id="key_first" v-model="dataKeys.key_first" type="text" />
      </div>
    </div>
    <div class="form__unit">
      <c-label text="Key second" />
      <div>
        <input id="key_second" v-model="dataKeys.key_second" type="text" />
      </div>
    </div>

    <div class="form__unit">
      <div class="cabinet__description">Электронные ключи необходимы для удобства пополнения счета</div>
      <button class="primary" @click.prevent="send">Сохранить</button>
    </div>
  </form>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import animation from '@/utils/animation';
import CLabel from '@/components/UI/ComponentLabel.vue';

export default {
  name: 'CabinetComponent',
  components: { CLabel },
  data: function () {
    return {
      dataKeys: {},
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      newKey: 'newKey',
    }),
    send(e) {
      animation.pressing(e);
      console.log(this.dataKeys);
    },
  },
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped></style>
