import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';

import AuthTemplate from '@/templates/AuthTemplate.vue';
import MainTemplate from '@/templates/MainTemplate.vue';

import AuthForm from '@/views/Auth.vue';
import IndexView from '@/views/Index.vue';
import Registration from '@/views/Registration.vue';
// import PersonalData from '@/views/PersonalData.vue';
// import ElectronicDocumentManagement from '@/views/ElectronicDocumentManagement.vue';
import Password from '@/views/Password.vue';
// import Questionnaire from '@/views/Questionnaire.vue';
// import ClientsData from '@/views/ClientsData.vue';
import TestPage from '@/views/TestPage.vue';

// routes for mobile view
import MobileWatchList from '@/views/mobile/WatchList.vue';
import MobileOrder from '@/views/mobile/Order.vue';
import MobilePortfolio from '@/views/mobile/Portfolio.vue';
import MobileOrders from '@/views/mobile/Orders.vue';
import MobileSearchList from '@/views/mobile/SearchList.vue';

const routes = [
  {
    path: '/login',
    component: AuthTemplate,
    children: [
      { path: '', name: 'login', component: AuthForm },
      { path: '/registration', name: 'registration', component: Registration },
      { path: '/password', name: 'password', component: Password },
      // { path: '/personal-data', name: 'personal.data', component: PersonalData },
      // { path: '/electronic-document-management', name: 'electronic.document.management', component: ElectronicDocumentManagement },
    ],
  },
  {
    path: '/',
    component: MainTemplate,
    meta: { requiresAuth: true },
    children: [
      { path: '', name: 'index', component: IndexView },
      { path: '/test', name: 'test', component: TestPage },
      // { path: '/questionnaire', name: 'questionnaire', component: ClientsData },

      // routes for mobile view
      { path: '/wl', meta: { title: 'Watch list' }, name: 'wl', component: MobileWatchList },
      { path: '/portfolio', meta: { title: 'Портфель' }, name: 'portfolio', component: MobilePortfolio },
      { path: '/orders', meta: { title: 'Ордера' }, name: 'orders', component: MobileOrders },
      { path: '/order', meta: { title: 'Ордер' }, name: 'order', component: MobileOrder },
      { path: '/search-list', meta: { title: 'Найти инструмент' }, name: 'search.list', component: MobileSearchList },
      // { path: '/questionnaire', meta: { title: 'Информация' }, name: 'questionnaire', component: ClientsData },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.isLoggedIn) {
      next();
      return;
    }
    next('/login');
  } else {
    next();
  }
});

export default router;
