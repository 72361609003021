<template>
  <div class="reset-password">
    <div class="authentication">
      <div class="authentication-form">
        <div class="wrapper-inner">
          <div class="logo">
            <img :src="img.blueLogo" alt="" />
          </div>
          <form class="form" @submit.prevent="setPassword">
            <!-- <div v-if="user.type === 'registration'" class="input-wrap">
              <div class="title">Введите ИИН</div>
              <input
                v-model="form.taxNumber"
                autocomplete="off"
                pattern="\d{8,}"
                required
                title="Введите ваш налоговый номер"
                type="text"
                @input="validateInputTaxNumber($event.target)"
              />
            </div> -->
            <div v-if="user.type !== 'registration'" class="form__unit">
              <c-label text="Введите старый пароль" />
              <div class="icon-input-wrap">
                <input v-model="form.password.old" autocomplete="off" type="password" />
                <div class="eye-icon" @blur="closeEyelids" @mousedown="openEyelids" @mouseleave="closeEyelids">
                  <img :src="icons.eye" alt="" />
                </div>
              </div>
              <!--              <button @blur="closeEyelids" @mousedown="openEyelids" @mouseleave="closeEyelids">&#x1F441;</button>-->
            </div>

            <!-- <div v-else class="input-wrap">
              <div class="title">Введите email</div>
              <input v-model="form.email" type="text" />
            </div> -->
            <div class="form__unit">
              <c-label text="Придумайте пароль" />
              <span v-show="form.password.alert" class="error">Пароли не совпадают</span>
              <div class="icon-input-wrap">
                <input
                  v-model="form.password.new"
                  autocomplete="new-password"
                  pattern=".{8,}"
                  required
                  title="Введите минимум 8 символов, включая заглавные буквы и знаки"
                  type="password"
                  @input="validateInputPassword($event.target)"
                />
                <div class="eye-icon" @blur="closeEyelids" @mousedown="openEyelids" @mouseleave="closeEyelids">
                  <img :src="icons.eye" alt="" />
                </div>
              </div>
              <!--              <button @blur="closeEyelids" @mousedown="openEyelids" @mouseleave="closeEyelids">&#x1F441;</button>-->
            </div>
            <div class="form__unit">
              <c-label text="Введите повторно пароль" />
              <span v-show="form.password.alert" class="error">Пароли не совпадают</span>
              <input
                v-model="form.password.confirm"
                autocomplete="new-password"
                pattern=".{8,}"
                required
                title="Пароль должен совпадать с введенным выше"
                type="password"
                @blur="validateConfirm"
                @input="validateInputPassword($event.target)"
              />
            </div>
            <div class="form__footer">
              <button :disabled="form.password.alert" class="primary center" type="submit">
                {{ user.type === 'registration' ? 'Отправить' : 'Сбросить' }}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { img, icons } from '@/assets/svg-img';
import CLabel from '@/components/UI/ComponentLabel.vue';

export default {
  name: 'password-page',
  components: { CLabel },
  data() {
    return {
      icons,
      img,
      form: {
        password: { old: '', new: '', confirm: '', alert: false },
        email: { new: '', confirm: '', alert: false },
        // taxNumber: '',
      },
    };
  },
  computed: {
    ...mapGetters({
      user: 'getUser',
    }),
  },
  methods: {
    ...mapMutations({
      changeNotifications: 'changeNotifications',
    }),
    ...mapActions({
      resetPassword: 'resetPassword',
      // sendTaxNumber: 'sendTaxNumber',
      terminalUser: 'terminalUser',
    }),
    // validateInputTaxNumber(target) {
    //   target.value = target.value.replace(/\D+/g, '');
    // },
    validateInputPassword(target) {
      target.value = target.value.replace(/[А-Яа-я ]/g, '');
      if (this.form.password.alert) this.validateConfirm();
    },
    validateConfirm() {
      this.form.password.alert = !(this.form.password.new === this.form.password.confirm);
    },
    async setPassword() {
      this.form.password.alert = false;
      if (this.form.password.new.length === 0) return void console.log('New password error!!!');

      let response = { text: '', status: '' };
      if (this.user.type === 'registration') {
        // if (this.form.taxNumber === 0) return void console.log('tax number error!!!');

        // const sendTaxNumber = await this.sendTaxNumber({ form: { taxNumber: this.form.taxNumber } });
        // if (sendTaxNumber.error) return void console.log('sendTaxNumber error: ', sendTaxNumber.text);
        response = await this.terminalUser({ user: this.user, password: this.form.password });
      } else {
        if (this.form.password.old.length === 0) return void console.log('Old password error!!!');

        response = await this.resetPassword({ password: this.form.password });
      }
      this.changeNotifications({ text: response.text, style: 'green', type: response.status, duration: 10 });
      return void this.$router.push({ name: 'login' });
    },
    openEyelids(e) {
      e.target.closest('.input-wrap').querySelector('input').type = 'text';
    },
    closeEyelids(e) {
      e.target.closest('.input-wrap').querySelector('input').type = 'password';
    },
  },
  watch: {},
  created() {},
  mounted() {
    if (this.user.id === null || this.user.login === null) {
      this.$router.push({ name: 'login' });
    }
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.reset-password {
  .icon-input-wrap {
    position: relative;
  }

  .error {
    color: var(--error);
    font-size: 10px;
  }

  .eye-icon {
    width: 15px;
    height: 15px;
    position: absolute;
    right: 6px;
    top: 50%;
    transform: translate(0, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
