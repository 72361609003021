<template>
  <div :class="scale ? 'scale' : ''" class="custom-select">
    <div :class="isDropdownOpen ? 'wide' : ''" class="custom-select-wrap">
      <div :data-value="selectedOption.value" class="select-header" @click="toggleDropdown">
        <span :class="[disabled ? 'disabled' : '', selectedOption.type === 'draft' ? 'gray' : '', 'title']">{{ selectedOption.text }}</span>
        <span :class="{ 'arrow-up': isDropdownOpen }" class="arrow"></span>
      </div>
      <input type="text" v-model="hiddenValue" :required="required" style="position: absolute; inset: 0; z-index: -1" />
      <div v-if="isDropdownOpen" class="options">
        <input v-if="searchable" ref="searchInput" v-model="searchQuery" placeholder="Search..." type="text" @input="filterOptions" />
        <div v-if="user.type === 'manager' && Object.keys(filters).length > 0" class="row">
          <div v-for="filter in filters" :key="filter.field">
            <c-check
              v-if="filter.type === 'checkbox'"
              v-model="filter.checked"
              :name="filter.field"
              :title="filter.text"
              :type="filter.type"
            />
          </div>
        </div>
        <div class="options-items">
          <div
            v-for="option in processingOptions(options)"
            :key="option.value"
            :class="[option.type === 'draft' ? 'gray' : '', 'item']"
            @click="selectOption(option)"
          >
            {{ option.text }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CCheck from '@/components/UI/ComponentCustomCheck.vue';

export default {
  name: 'ComponentSelect',
  components: { CCheck },
  props: {
    disabled: { type: Boolean, default: false },
    searchable: { type: Boolean, default: false },
    scale: { type: Boolean, default: false },
    options: Array,
    modelValue: [String, Number],
    defaultOption: [String, Number],
    onChange: Function,
    filters: { type: Object, default: () => ({}) },
    user: { type: Object, default: () => ({}) },
    required: { type: Boolean, default: false },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      isDropdownOpen: false,
      selectedOption: this.findOptionByValue(this.modelValue),
      searchQuery: '',
      hiddenValue: null,
    };
  },
  methods: {
    toggleDropdown() {
      if (!this.disabled) this.isDropdownOpen = !this.isDropdownOpen;
    },
    selectOption(option) {
      this.selectedOption = option;
      this.isDropdownOpen = false;
      this.hiddenValue = option.text;
      this.$emit('update:modelValue', option.value, option.text);
      if (this.onChange) {
        this.onChange(option.value);
      }
    },
    findOptionByValue(value) {
      return this.options.find((option) => option.value === value) || { value: null, text: this.defaultOption };
    },
    closeDropdownOnClickOutside(event) {
      if (!this.$el.contains(event.target)) this.isDropdownOpen = false;
    },
    processingOptions(options) {
      for (const key in this.filters) {
        if (this.filters[key].checked === true) {
          options = options.filter((each) => each.contactId === this.user.id || this.filters[key].value.includes(each[key]));
        }
      }
      const query = this.searchQuery.toLowerCase();
      return options.filter((option) => option.text.toLowerCase().includes(query));
    },
  },
  watch: {
    modelValue(newValue) {
      this.selectedOption = this.findOptionByValue(newValue);
    },
    isDropdownOpen(newState) {
      if (newState === false) {
        this.searchQuery = '';
      }
    },
  },
  mounted() {
    document.addEventListener('click', this.closeDropdownOnClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener('click', this.closeDropdownOnClickOutside);
  },
};
</script>

<style lang="scss" scoped>
@import '../../style/helper/index';

.custom-select {
  position: relative;
  color: var(--black);
  width: 100%;
  height: 24px;

  &-wrap {
    height: 100%;
  }
}

.scale {
  z-index: 333333;
  height: 100%;

  .custom-select-wrap {
    z-index: 10;
    height: 100%;

    &:hover,
    &.wide {
      position: absolute;
      min-width: 100%;
      @include breakpoint-down(md) {
        max-width: 230px;
      }

      .select-header {
        padding: 5px 10px;
      }
    }
  }
}

.select-header {
  cursor: pointer;
  border-radius: var(--main-border-radius);
  padding: 0 10px;
  transition: all 0.1s ease;
  -webkit-appearance: none;
  background-color: var(--input-alt-default-bg);
  border: 1px solid var(--input-alt-default-border-outside);
  color: var(--input-alt-default-text);
  box-shadow: inset 0 1px 0 var(--input-default-shadow-inner-color);
  display: flex;
  align-items: center;
  height: 100%;

  @include breakpoint-down(md) {
    height: 24px;
  }

  .title {
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    pointer-events: none;
  }
}

.arrow-up {
  transform: rotate(180deg);
}

.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  z-index: 99;
  padding: 5px 6px 10px;
  border: 1px solid;
  border-color: var(--dropdown-list-border-color);
  background-color: var(--dropdown-list-bg-color);
  color: var(--dropdown-list-text-color);
  box-shadow: 0 2px 10px 0 var(--overlay-box-shadow-color);
  font-size: 12px;
  border-radius: var(--main-border-radius);
  min-width: 70px;

  input {
    width: 100%;
    margin-bottom: 2px;
    height: 30px;
    padding: 2px 10px;
    font-size: 14px;
  }

  .options-items {
    max-height: calc(90vh - 30px);
    overflow: scroll;
    padding: 0 0 5px;
    margin-top: 5px;
    width: 100%;
  }

  .row {
    display: flex;
  }
}

.options .item {
  padding: 5px 10px;
  cursor: pointer;
  transition:
    background 0.2s ease,
    transform 0.1s ease-in-out;
  font-size: 14px;
  border-radius: var(--main-border-radius);
  transform: translate(0);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0);
}

.options .item:hover {
  background-color: var(--list-item-hovered-bg);
  transform: translate(0, -2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
}

.disabled {
  color: #6c757d;
}

.gray {
  color: rgba(128, 128, 128, 1);
}
</style>
